import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faTwitch, faFacebook, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons'

export const Socials = (props) => {
  return (
    <div id='socials' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Socials</h2>
        </div>
        <div className='row'>
          <a href='https://twitter.com/AxieFacts?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'>
            <div  className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faTwitter} className='social-icons twitter'/>
              <h3>Twitter</h3>
              <p>@AxieFacts</p>
            </div>
          </a>
          <a href='https://www.facebook.com/AxieFacts/'>
            <div  className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faFacebook} className='social-icons facebook'/>
              <h3>Facebook</h3>
              <p>facebook.com/AxieFacts</p>
            </div>
          </a>
          <a href ='https://www.twitch.tv/axiefacts'>
            <div className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faTwitch} className='social-icons twitch'/>
              <h3>Twitch</h3>
              <p>twitch.tv/AxieFacts</p>
            </div>
          </a>
          <a href='https://discord.com/channels/857095506261966869'>
            <div className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faDiscord} className='social-icons discord'/>
              <h3>Discord</h3>
              <p>AxieFacts Family</p>
            </div>
          </a>
          <a href='https://www.youtube.com/channel/UCuxE4EM5fB7fPfcAqSZA5Gg'>
            <div  className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faYoutube} className='social-icons youtube'/>
              <h3>YouTube</h3>
              <p>AxieFacts</p>
            </div>
          </a>
          <a href='https://www.youtube.com/channel/UCk65SRUtCjEKwtOSA0NBmOQ'>
            <div className='col-xs-6 col-md-2'>
              <FontAwesomeIcon icon={faYoutube} className='social-icons youtube'/>
              <h3>YouTube</h3>
              <p>Web3 and Me</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
